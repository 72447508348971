import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Helmet from "react-helmet";

const WebDesigner = ({ location: { pathname }, pageContext }) => {
  const title = "Web Designer";

  return (
    <Layout
      seo={{
        title: title,
        href: pathname,
        lang: "pl",
      }}
    >
      <Helmet>
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
        ></script>
      </Helmet>
      <section className="page_recrutation">
        <h1>Kariera</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item">
                <Link to="/kariera/account-specialist">Account Specialist</Link>
              </li>
              {/* <li className="cat-item">
                <Link to="/kariera/strategy-planner">Strategy Planner</Link>
              </li> */}
              <li className="cat-item active">
                <Link to="/kariera/web-designer">Web Designer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/react-developer">React.js Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/php-developer">PHP Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/python-developer">
                  Python & Django developer
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>
                Miejsce pracy: <strong>Kraków</strong>
              </p>
              <p>
                ADream to grupa spółek (naszych własnych) z kluczowych obszarów
                marketingu. Tworzymy nowe brandy i wprowadzamy je na rynek,
                odświeżamy i rozwijamy również te już istniejące. Nasze główne
                obszary działania to; strategia, identyfikacja wizualna,
                opakowania i etykiety, serwisy i aplikacje internetowe, serwisy
                ecommerce, kampanie wizerunkowe i sprzedażowe, social media - to
                tak w skrócie:) Współpracujemy z Klientami z Polski i z
                zagranicy.{" "}
              </p>
              <h2>Opis stanowiska:</h2>
              <p>
                Z chęcią przywitamy w teamie osobę o szerokich horyzontach w
                zakresie projektowania. Jeżeli dobrze czujesz się zarówno w
                webdesignie jak i projektowaniu identyfikacji to perfekcyjnie:)
                Jeżeli jednak Twoja specjalizacja jest węższa, ale czujesz, że
                robisz to dobrze to również bardzo chętnie porozmawiamy o naszej
                współpracy:)
              </p>
              <p>
                Jeżeli uważasz, że DEADLINE to nie stres a motywacja, a
                MULTITASKING to nie chaos a wachlarz możliwości to szanse na
                naszą współpracę znacząco wzrastają:)
              </p>
              <h2>W ADream będziesz mieć możliwość projektowania:</h2>
              <ul>
                <li>KV</li>
                <li>identyfikacji wizualnych</li>
                <li>stron, sklepów i aplikacji internetowych</li>
                <li>materiałów digital</li>
                <li>materiałów POS</li>
                <li>katalogów</li>
                <li>opakowań i etykiet</li>
              </ul>
              <h2>Oczekujemy:</h2>
              <ul>
                <li>doświadczenia w preferowanym obszarze projektowania</li>
                <li>fajnego portfolio</li>
                <li>kreatywności oraz skilla w poszukiwaniu benchmarków</li>
                <li>umiejętności i chęci dyskutowania o projektach</li>
                <li>
                  odporności na dedlajnowe stresy oraz gotowość do pracy
                  stacjonarnej w naszym biurze (praca zdalna możliwa jedynie
                  doraźnie)
                </li>
                <li>doświadczenia w środowisku Adobe</li>
                <li>inicjatywy i pozytywnego nastawienia</li>
              </ul>
              <h2>Oferujemy:</h2>
              <ul>
                <li>pracę w klimatyzowanym biurze w centrum Krakowa</li>
                <li>
                  różnorodność projektów (od średnich po duże, od kreatywnych po
                  te uczące cierpliwości ;)
                </li>
                <li>formę współpracy dopasowaną do potrzeb (umowa/b2b)</li>
                <li>
                  obowiązkową przerwę regeneracyjną (będziesz pracował 7 godzin
                  i 15 minut a 45 minut poświęcisz na przerwę; wyjście na taras,
                  do pobliskiego parku czy na obiad)
                </li>
                <li>pyszną kawę z ekspresu</li>
                <li>świeże owoce</li>
                <li>wyjazdy integracyjne</li>
                <li>fajny team!</li>
              </ul>
              <h2>Wymagane informacje i dokumenty:</h2>
              <p>
                Najbardziej interesują nas Twoje chęci i umiejętności dlatego
                wystarczy jak prześlesz jedynie portfolio wraz z informacją w
                jakim obszarze możesz projektować oraz w którym z nich czujesz
                się najlepiej - resztę omówimy na spotkaniu:)
              </p>
              <p>
                Jeśli czujesz, że masz w sobie ogień prometeusza i możesz z nami
                nieść pochodnię designu, aplikuj. Jeśli potrafisz rozniecać
                blask w firmach naszych klientów, chcesz każdego jutro tworzyć
                projekty lepsze niż wczoraj a słabe projekty od razu spalić w
                swoim ogniu, aplikuj. Jeśli nie cierpisz przeciętności i pracy
                na odwal się, aplikuj. Czekamy na Ciebie!
              </p>
              <iframe
                id="web-designer-form"
                title="Recrutation form - Web designer"
                class="clickup-embed clickup-dynamic-height"
                src="https://forms.clickup.com/4615748/f/4cvj4-5688/JQRSIFVS830X1BK86T"
                onwheel=""
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
              <p className="page_recrutation_signature">
                Administratorem Danych Osobowych jest Adream sp. z o.o. z
                siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132. Podanie
                danych osobowych w CV jest dobrowolne, lecz konieczne do
                przeprowadzenia procesu rekrutacyjnego. Konsekwencją niepodania
                danych osobowych będzie brak możliwości przeprowadzenia
                postępowania rekrutacyjnego. Dane osobowe przetwarzane będą na
                podstawie art. 6 ust. 1 pkt. a i c ogólnego rozporządzenia o
                ochronie danych osobowych z 27 kwietnia 2016 r. (RODO).
                Przysługuje Pani/ Panu prawo dostępu do treści swoich danych
                osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia
                przetwarzania, prawo do przenoszenia danych, prawo do wniesienia
                sprzeciwu, prawo do cofnięcia zgody na ich przetwarzanie w
                dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej cofnięciem.
                Przysługuje Pani/Panu prawo wniesienia skargi na przetwarzanie
                danych do Prezesa Urzędu Ochrony Danych Osobowych. Dane osobowe
                będą przetwarzane do momentu zakończenia prowadzenia rekrutacji,
                nie dłużej jednak niż przez trzy miesiące od dnia przesłania CV.
                Za zgodą kandydatów w okresie 1 roku od zakończenia procesu
                rekrutacyjnego dane osobowe będą przetwarzane w kolejnych
                rekrutacjach prowadzonych przez administratora danych osobowych.
                Dane osobowe kandydatów nie będą podlegały zautomatyzowanemu
                przetwarzaniu.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default WebDesigner;
